import '../styles/index-footer.css'
import './particles.min';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import $ from "jquery";

$(document).ready(function (e) {
    $('#mobile-menu-icon').on('click', function (e) {
        $('.responsive-menu.main').toggleClass('active');
        $('.body-overlay').toggleClass('active');
    });
    let is_op_wrapper = $('.page-3-header-image.survey').length;
    if(!is_op_wrapper){
        setTimeout(function () {
            $('.op-wrapper').fadeIn();
        }, 4000);
    }

    $('#exit-op').on('click', function (e) {
        e.preventDefault();
        $('.op-wrapper').fadeOut();
    });

    $('.body-overlay').on('click', function (e) {
        $('.responsive-menu').removeClass('active');
        $('.body-overlay').removeClass('active');
    });
    $('.responsive-menu ul li.has-items a').on('click', function (e) {
        e.preventDefault();
        let _target = $(this).data('target');
        $(_target).toggleClass('active');
    });
    $('.responsive-menu-back i').on('click', function (e) {
        $('.responsive-menu.sub-menu').removeClass('active');

    });
    particlesJS.load('particles-js', '/web/json/particles.json', function () {
    });
    $('#close-general-search').on('click', function (e) {
        e.preventDefault();
        $('#close-general-search').fadeOut();
        $('.search-body-wrapper').removeClass('active');
        $('body').removeClass('body-overflow');
    });
});